<template>
  <div v-if="authUser" class="container-xxl">
    <div class="row">
      <div class="col">
        <PageTitle :title="'Menu'"></PageTitle>

        <div class="list-group">
          <router-link
            to="/account"
            class="list-group-item list-group-item-action"
            role="button"
          >Account</router-link>
          <router-link
            to="/metric"
            class="list-group-item list-group-item-action"
            role="button"
          >Metrics</router-link>
          <div
            @click="showFindCoachModal = true"
            v-if="authUserIsAthlete && !hasCoach"
            class="list-group-item list-group-item-action"
            role="button"
          >Find a coach</div>
          <div @click="showConfirmModal = true" class="list-group-item list-group-item-action">
            <span class="text-danger">Logout</span>
          </div>
        </div>

        <portal to="modal">
          <transition name="modal">
            <FindCoachModal
              @close="showFindCoachModal = false"
              v-if="showFindCoachModal"
              v-model="showFindCoachModal"
            ></FindCoachModal>
          </transition>
        </portal>

        <portal to="modal">
          <transition name="modal">
            <ConfirmModal
              @confirm="logout"
              @close="showConfirmModal = false"
              v-model="showConfirmModal"
              v-if="showConfirmModal"
            ></ConfirmModal>
          </transition>
        </portal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Settings',
  components: {
    PageTitle: () => import('@/components/PageTitle'),
    FindCoachModal: () => import('@/components/modal/FindCoachModal'),
    ConfirmModal: () => import('@/components/modal/ConfirmModal'),
  },
  computed: {
    ...mapState('auth', ['authUser']),
    ...mapGetters('auth', ['authUserIsAthlete']),
    hasCoach() {
      const { authUser } = this;
      if (!authUser) return false;
      return !!(authUser.coaches && authUser.coaches.length);
    },
  },
  methods: {
    logout() {
      this.showConfirmModal = false;
      this.$store.dispatch('calendar/resetCalendar');
      this.$store.dispatch('client/resetRequest');
      this.$store.dispatch('comment/resetComment');
      this.$store.dispatch('feed/resetFeed');
      this.$store.dispatch('library/reset');
      this.$store.dispatch('metric/reset');
      this.$store.dispatch('notification/resetNotification');
      this.$store.dispatch('program/reset');
      this.$store.dispatch('session/reset');
      this.$store.dispatch('workout/resetWorkout');
      this.$store.dispatch('auth/logout');
    },
  },
  data() {
    return {
      showFindCoachModal: false,
      showConfirmModal: false,
    };
  },
};
</script>
